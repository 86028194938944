import styled from 'styled-components'

export const Cardbox = styled.div`
  position: relative;
  display: inline-block;
  width: 11em;
  height: 9em;
  text-align: center;
`

export const Title = styled.h4`
  font-size: 26px;
  line-height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
