import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

import * as S from './Card.styles'

const Card = ({ thumbnail, title, date, description, path }) => (
  <S.Card>
    {/* <S.Ratio>
      <S.Thumbnail
        src={thumbnail}
      />
    </S.Ratio> */}
    <S.Meta>
      <Text type='title'>{title}</Text>
      <S.Date>{date}</S.Date>
      <Text>{description}</Text>
    </S.Meta>
    <S.Button to={path}>Read More</S.Button>
  </S.Card>
)

Card.defaultProps = {
  // thumbnail: ``,
  title: ``,
  date: ``,
  description: ``,
  path: `/insights`,
}
Card.propTypes = {
  // thumbnail: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
}

export default Card
