import React from 'react'

import * as S from './Cardbox.styles'

const Cardbox = ({ title }) => (
  <S.Cardbox>
    <S.Title>{title}</S.Title>
  </S.Cardbox>
)

export default Cardbox
