import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import Container from '../Container'
import Cardbox from '../Cardbox'
import Text from '../Text'

import * as S from './Objectives.styles'

const Objectives = () => (
  <S.Objectives>
    <Container>
      <Text
        type='headline'
      >
        We invest in companies that leverage differentiated technology and scalable business models to drive innovation
      </Text>
        <Row
          around='sm'
          style={{
            borderTop: '2px solid #104278',
            borderBottom: '2px solid #104278'
          }}
        >
          <Col>
            <Cardbox title='Fintech / Insuretech' />
          </Col>
          <Col>
            <Cardbox title='Consumer Technologies' />
          </Col>
          <Col>
            <Cardbox title='Blockchain Technologies' />
          </Col>
          <Col>
            <Cardbox title='Digital Health' />
          </Col>
        </Row>
    </Container>
  </S.Objectives>
)

export default Objectives
