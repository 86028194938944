import React from 'react'
import { graphql, Link } from 'gatsby'
import { Row, Col } from 'react-flexbox-grid'

import Page from '../components/Page'
import Container from '../components/Container'
import Button from '../components/Button'
import Section from '../components/Section'
import Card from '../components/Card'
import Companies from '../components/Companies'
import Objectives from '../components/Objectives'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Page
    title='Your Gate to Success'
    background='images/pages/Home.jpg'
  >
    <Container>
      <Section
        title='What We Do'
        subtitle='Mission Gate is a venture capital firm backing startups and entrepreneurs from all over the world with necessary resources for realizing their full potential. We invest in seed and early-stage startups in the internet, mobile, blockchain, innovative consumer products and other industries. Through our network and experience, we are connecting entrepreneurs with the industry leaders and top talent, while providing them with an entire spectrum of insights to guide them through all phases of growth.'
      >
        <Objectives />
      </Section>
      <Section
        title='Recent Deals'
      >
        <Companies type='homepage' />
      </Section>
      <Section
        title='Insights'
      >
        <Row>
          { edges.map((p, i) => (
            <Col md={4} sm={12} xs={12} key={i}>
              <Card
                title={p.node.frontmatter.title}
                date={p.node.frontmatter.date}
                description={p.node.frontmatter.teaser}
                path={p.node.frontmatter.path}
              />
            </Col>
          )) }
        </Row>
        <Row around='lg'>
          <Link to='/insights'>
            <Button appearance='primary'>View All Insights</Button>
          </Link>
        </Row>
      </Section>
    </Container>
  </Page>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 3) {
      edges {
        node {
          id
          frontmatter {
            date
            path
            title
            teaser
          }
        }
      }
    }
  }
`
