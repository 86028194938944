import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'

export const Companies = styled.div`
  .react-responsive-modal-modal {
    margin: auto;
  }
`

export const Image = styled.img`
  height: 90%;
  width: 100%;
  object-fit: contain;
  filter: grayscale(1);
  cursor: pointer;
  margin-bottom: ${props => props.margin ? '0px' : null};

  @media screen and (max-width: 768px) {
    margin: 2em 0;
  }

  &:hover {
    filter: grayscale(0);
  }
`

export const ModalTitle = styled.h1`
  font-size: 1.625em;
  line-height: 1.45em;
  margin-top: .5em;
  margin-bottom: .5em;


`

export const ModalContent = styled.p`
  font-size: 1.25em;
  font-weight: 100;
  line-height: 1.275em;

  a {
    text-decoration: none;
    color: #a3aaae;
    float: right;
    margin-top: 3em;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-family: 'Guardian Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;

    &:hover {
      color: #104278;
    }
  }
`

export const SModal = styled(Modal)`
  margin: auto;
`
