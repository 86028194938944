import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import 'react-responsive-modal/styles.css'

import Section from '../Section'
import Container from '../Container'

import * as S from './Companies.styles'

import OLAgg from '../../../static/images/companies/OLAgg.png'
import Sangha from '../../../static/images/companies/sang.png'
import Customily from '../../../static/images/companies/cust.png'
import FastFarma from '../../../static/images/companies/Fastfarma.png'
import Coinfirm from '../../../static/images/companies/Coinfirm.png'
import Aionrise from '../../../static/images/companies/Aionrise.png'
import DNAnudge from '../../../static/images/companies/DNAnudge.png'
import Youtravelme from '../../../static/images/companies/Youtravelme.png'
import whereismytransport from '../../../static/images/companies/Whereismytransport.png'
import zoundream from '../../../static/images/companies/Zoundream.png'
import statiq from '../../../static/images/companies/Statiq.png'
import visor from '../../../static/images/companies/Visor.png'
import reakiro from '../../../static/images/companies/reakiro.png'
import hotkeyexcellence from '../../../static/images/companies/hotkeyexcellence.png'
import hut8 from '../../../static/images/companies/hut8.png'
import cryptomat from '../../../static/images/companies/cryptomat.png'
import brokers from '../../../static/images/companies/brokers.png'
import bitfury from '../../../static/images/companies/bitfury.png'
import elefund from '../../../static/images/companies/elefund.png'

const data = [{
  image: OLAgg,
  url: 'https://olagg.io/',
  title: 'OLA GG',
  content: 'Ola GG is a subDAO of Yield Guild Games, a gaming guild that acquires NFT assets to loan to Hispanic people. Ola GG implements their scholar program to allow users to earn tokens from blockchain games. Through this program, users mitigate all the risk associated with blockchain gaming, and are able to start earning tokens quicker. These tokens can then be converted to their local currencies allowing them make a living from participating in these communities.'
},{
  image: Sangha,
  url: 'https://www.sanghacapital.co/',
  title: 'Sangha Capital',
  content: 'A purpose driven tech investment club. Investing in technology start-ups that solve the United Nations Sustainable development goals. Sangha team believes technology has the potential to drive global progress towards the SDGs, impacting the world with the scale and speed society needs to overcome current and future challenges.'
},{
  image: Customily,
  url: 'https://www.customily.com/',
  title: 'Customily',
  content: 'Customily integrates its system into any e-commerce website and gives customers possibility to personalize products online. With Customily, stores can give customers instant previews of their personalization and receive a ready-to-print engrave or embroider file with every order.'
},{
  image: FastFarma,
  url: 'https://www.fastfarma.com/',
  title: 'FastFarma',
  content: 'FastFarma is a new concept of digital pharmacy for Latam, simplifying the purchase experience, incorporating subscriptions, digital prescription, telehealth and automatic insurance claims, offering competitive prices. It improves the lives of customers by giving them access to health through a great selection of products, delivered in a direct, efficient, and secure manner, reducing costs.'
},{
  image: Coinfirm,
  url: 'http://coinfirm.com/',
  title: 'Coinfirm',
  content: 'Coinfirm is a crypto AML/KYC compliance and analytics company. Coinfirm’s industry-leading risk management helps cryptocurrencies and blockchain protocols detect suspicious activities early on and warn them about processing transactions that could be associated with high level of risk.'
},{
  image: Youtravelme,
  url: 'https://youtravel.me/',
  title: 'Youtravele.me',
  content: 'YouTravel.me is a laid-back, no-strings-attached approach to small group travel that feels more like exploring the world with old friends than anything else. Their less-restricting itineraries mean spontaneous, fun-filled opportunities to capture the best of what every spot along the way has to offer.'
}, {
  image: whereismytransport,
  url: 'https://www.whereismytransport.com/',
  title: 'WhereIsMyTransport',
  content: 'In emerging-market megacities, hundreds of millions of people use public transport to get where they need to go. Before WhereIsMyTransport, there was no central source of data for these networks, despite their size, scale, and importance. WhereIsMyTransport helps people understand mobility in emerging-market megacities.'
}, {
  image: Aionrise,
  url: 'https://aionrise.com/',
  title: 'AIONRISE',
  content: 'AIONRISE is a US-based manufacturer of solar panels – the main global power source of the future. The Company has established a fully automated cutting-edge manufacturing facility in Georgia. Its products comply with international standards and use superior quality materials registered by TÜV Rheinland.'
}, {
  image: DNAnudge,
  url: 'https://www.dnanudge.com/',
  title: 'DNAnudge',
  content: 'DnaNudge’s COVID Nudge test is a rapid, accurate, portable and lab-free RT-PCR test that delivers results at the point of need and in just over an hour. Apart from COVID-19 testing, DnaNudge specializes in applying DNA analysis to healthier food choices.'
}, {
  image: zoundream,
  url: 'https://zoundream.com/',
  title: 'Zoundream',
  content: 'Zoundream knows one universal language: baby cries. Its unique AI algorithm can detect each of every baby’s five basic needs by identifying rather distinctive crying sound patterns. Whether your baby needs feeding, sleeping, stomach pain relief, burping or comfort, Zoundream will let you know in an instant'
}, {
  image: statiq,
  url: 'https://www.statiq.in/',
  title: 'Statiq',
  content: 'Statiq is India’s largest EV charging network for cars and bikes. It helps users find, book and pay for smart public EV chargers at parking places such as office spaces, shopping malls, residential apartments, restaurants and hotels'
}, {
  image: visor,
  url: 'https://visor.io/',
  title: 'Visor',
  content: 'Visor is a Latin American fintech startup, providing SME suppliers, lenders and other stakeholders with dynamic, up to date and unbiased SME financial health data'
}, {
  image: hotkeyexcellence,
  url: 'https://hotkeyexcel.com/',
  title: 'KeySkillset',
  content: 'KeySkillSet is an EdTech startup, helping both learners and professionals become more efficient in Excel, Powerpoint and Python through engaging games'
},{
  image: reakiro,
  url: 'http://reakiro.com/',
  title: 'Reakiro',
  content: 'Reakiro Europe is an EU based Health & Wellness startup providing hemp-based CBD products to the European market. High quality, natural only range of products differentiates Reakiro from its competitors. It provides products, such as tinctures and creams, both for mental and physical wellness'
},{
  image: hut8,
  url: 'https://hut8mining.com',
  title: 'HUT8',
  content: 'Traded on Toronto Stock Exchange, Hut 8 Mining is a cryptocurrency mining and blockchain infrastructure company established through an exclusive arrangement with the Bitfury Group. Hut 8 provides investors with direct access to bitcoin, without the technical complexity or constraints of purchasing the underlying cryptocurrency',
  scale: true
}, {
  image: cryptomat,
  url: 'https://cryptomat.ge',
  title: 'Cryptomat',
  content: 'Crypomat offers specialized ATMs where you can both buy and sell cryptocurrencies. Registering on crypto trading platforms may take weeks. While Cryptomat is always ready for instant transactions',
  scale: true
} , {
  image: brokers,
  url: 'https://www.brokers.io/',
  title: 'Brokers.IO',
  content: 'Brokers.IO enables its users to trade cryptocurrencies seamlessly on all major exchanges through a simple, innovative trading terminal. Whether you are an individual or an institution trader, with Brokers.IO your trades are just one click away'
} ,{
  image: elefund,
  url: 'https://www.elefund.com/',
  title: 'elefund',
  content: 'Started by serial entrepreneurs and investors – David Mavashev and Serik Kaldykulov, Elefund is a thesis driven VC investing in early stage companies across fintech, consumer internet, and other innovative industries'
} , {
  image: bitfury,
  url: 'https://bitfury.com/',
  title: 'Bitfury',
  content: 'Founded in 2011 Bitfury is one of the largest bitcoin mining and full service blockchain technology companies in the world. They develop and deliver cutting-edge software and hardware solutions necessary for businesses, governments, organizations and individuals to securely move assets across the blockchain'
} ]

const Companies = ({ type }) => {
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState({
    url: '',
    title: '',
    content: '',
  })
  const dataToMap = type === 'homepage' ? data.slice(0, 4) : data

  return (
    <S.Companies>
    <S.SModal open={open} onClose={() => { setOpen(false) }}>
      <S.ModalTitle>{modal.title}</S.ModalTitle>
      <S.ModalContent>{modal.content}<a href={modal.url} target='_blank' rel='noopener noreferrer'>Visit Website</a></S.ModalContent>
      
    </S.SModal>
    <Container>
      <Section>
        <Row between={dataToMap.length >= 4 ? null : 'md'}>
          { dataToMap.map((d, index) => {
            if (dataToMap.length >= 4) {
              return (<Col key={index} md={3}>
                <S.Image src={d.image} alt={d.title} title={d.title} margin={dataToMap.length > 4} onClick={() => {
                  setModal({ title: d.title, content: d.content, url: d.url })
                  setOpen(true)
                }}
                style={d.scale ? { scale: '1.5' } : null}
                />
              </Col>)
            } else {
              return (
                <Col key={index}>
                  <S.Image src={d.image} alt={d.title} title={d.title} margin={dataToMap.length > 4} onClick={() => {
                    setModal({ title: d.title, content: d.content, url: d.url })
                    setOpen(true)
                  }}
                  style={d.scale ? { scale: '1.5' } : null}
                  />
                </Col>
              )
            }
          }
          ) }
        </Row>
      </Section>
    </Container>
    </S.Companies>
  )
}

export default Companies
