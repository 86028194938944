import styled from 'styled-components'
import { Link } from 'gatsby'

export const Card = styled.div`
  margin-bottom: 2em;
`

export const Ratio = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 67.5%;
`

export const Thumbnail = styled.img`
  position: absolute;
  width: 100%;
`

export const Meta = styled.div`
  margin-bottom: 1em;
`

export const Date = styled.span`
  display: block;
  margin-top: .5em;
  margin-bottom: 1em;
  color: #a3aaae;
`

export const Button = styled(Link)`
  text-transform: uppercase;
  font-weight: 500;
  color: #104278;
  border-bottom: 2px solid #104278;
  text-decoration: none;
`
